<template>
<div class="container">
    <div class="row row-cols-2 row-cols-md-3 g-4">
        <div class="col home-body-project-card">
            <div class="card mb-4 rounded-3 shadow-sm h-100">
            <div class="card-header py-3">
                <h4 class="my-0 fw-normal">球球大作战</h4>
            </div>
            <div class="card-body">
                <h4 class="card-title pricing-card-title"><small class="text-muted fw-light">多人联机对战游戏</small></h4>
                <ul class="list-unstyled mt-3 mb-4">
                <li>鼠标右键控制移动</li>
                <li>Q + 鼠标左键发射火球</li>
                <li>F 向鼠标所在位置闪现</li>
                </ul>
            </div>
            <div class="card-footer border-light">
                <button @click="ball_fighting_entrance_click" type="button" class="w-100 btn btn-primary home-body-project-card-button">进入</button>
            </div>
            </div>
        </div>

        <div class="col home-body-project-card">
        <div class="card mb-4 rounded-3 shadow-sm h-100">
          <div class="card-header py-3">
            <h4 class="my-0 fw-normal">拳皇</h4>
          </div>
          <div class="card-body">
            <h4 class="card-title pricing-card-title"><small class="text-muted fw-light">单机双人游戏</small></h4>
            <ul class="list-unstyled mt-3 mb-4">
              <li>玩家一: WAD + Space 控制跳起移动和攻击</li>
              <li>玩家二: Up + Left + Right + Enter 控制跳起移动和攻击</li>
            </ul>       
          </div>
          <div class="card-footer border-light">
            <button @click="kof_entrance_click" type="button" class="w-100 btn btn-primary home-body-project-card-button">进入</button>
          </div>
        </div>
        </div>

        <div class="col home-body-project-card">
        <div class="card mb-4 rounded-3 shadow-sm h-100">
          <div class="card-header py-3">
            <h4 class="my-0 fw-normal">计算器</h4>
          </div>
          <div class="card-body">
            <h4 class="card-title pricing-card-title"><small class="text-muted fw-light">简易版计算器</small></h4>
            <ul class="list-unstyled mt-3 mb-4">
              <li>使用 React 实现的简易版计算器</li>
              <li>只有简单的计算功能</li>
            </ul>
          </div>
          <div class="card-footer border-light">
            <button type="button border-light" class="w-100 btn btn-primary home-body-project-card-button">进入</button>
          </div>
        </div>
        </div>

        <div class="col home-body-project-card">
        <div class="card mb-4 rounded-3 shadow-sm h-100">
          <div class="card-header py-3">
              <h4 class="my-0 fw-normal">贪吃蛇</h4>
          </div>
          <div class="card-body">
            <h4 class="card-title pricing-card-title"><small class="text-muted fw-light">贪吃蛇</small></h4>
            <ul class="list-unstyled mt-3 mb-4">
              <li>使用 Vue 实现的贪吃蛇</li>
            </ul>
            
          </div>
          <div class="card-footer border-light">
            <button type="button border-light" class="w-100 btn btn-primary home-body-project-card-button">进入</button>
          </div>
        </div>
        </div>

        <div class="col home-body-project-card">
        <div class="card mb-4 rounded-3 shadow-sm h-100">
          <div class="card-header py-3">
            <h4 class="my-0 fw-normal">AI 对战平台</h4>
          </div>
          <div class="card-body">
            <h4 class="card-title pricing-card-title"><small class="text-muted fw-light">AI 对战平台</small></h4>
            <ul class="list-unstyled mt-3 mb-4">
              <li>使用 Spring Boot 实现的AI对战平台</li>
              <li>前端使用 Vue 实现</li>
            </ul>
          </div>
          <div class="card-footer border-light">
            <button @click="kob_entrance_click" type="button" class="w-100 btn btn-primary home-body-project-card-button">进入</button>
          </div>
        </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'ApplicationView',
    setup() {
        const ball_fighting_entrance_click = () => {
            window.location.href = "https://www.chtld.cn/game/"
        }

        const kof_entrance_click = () => {
            window.location.href= "https://www.chtld.cn/kof/";
        }

        const kob_entrance_click = () => {
            window.location.href = "https://kob.chtld.cn/";
        }

        return {
            ball_fighting_entrance_click,
            kof_entrance_click,
            kob_entrance_click,
        }
    }
}
</script>

<style scoped>
.home-body-project-card {
    padding-top: 3vh;
    border-radius: 2%;
    text-align: center;
}

.home-body-project-card-button {
    border: none;
    text-align: center;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
</style>