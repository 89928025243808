<template>
    <ContentBase> 
          <div class="row justify-content-md-center">
            <div class="col-3">
                <form @submit.prevent="register">
                    <div class="mb-3">
                        <label for="username" class="form-label">用户名</label>
                        <input v-model="username" type="text" class="form-control" id="username">
                    </div>
                    <div class="mb-3">
                        <label for="password" class="form-label">密码</label>
                        <input v-model="password" type="password" class="form-control" id="password">
                    </div>
                    <div class="mb-3">
                        <label for="confirmed-password" class="form-label">确认密码</label>
                        <input v-model="confirmed_password" type="password" class="form-control" id="conformed-password">
                    </div>
                    <div class="error-message">
                        {{ error_message }}
                    </div>
                    <button type="submit" class="btn btn-primary">登录</button>
                </form>               
            </div>
        </div>
    </ContentBase>
</template>

<script>
import { ref } from '@vue/reactivity';
import ContentBase from '../components/ContentBase.vue';
import { useStore } from 'vuex';
import router from '@/router';
import $ from 'jquery';

export default {
    name: 'RegisterView',
    components: {
        ContentBase,
    },
    setup() {
        let username = ref('');
        let password = ref('');
        let confirmed_password = ref('');
        let error_message = ref('');
        const store = useStore();

        const register = ()=> {
            error_message.value = "";
            $.ajax({
                url: "https://www.chtld.cn/api/register/",
                type: "post",
                data: {
                    username: username.value,
                    password: password.value,
                    confirmed_password: confirmed_password.value,
                },
                success(resp) {
                    if (resp.result == "success") {
                        store.dispatch("login", {
                            username: username.value,
                            password: password.value,
                            success() {
                                router.push({name: 'home'});
                            },
                            error() {
                                error_message.value = "系统异常, 请稍后重试."
                            }
                        });
                    } else {
                        error_message.value = resp.result;
                    }
                }
            });
        }

        return {
            username,
            password,
            confirmed_password,
            error_message,
            register,
        }
    }
}
</script>


<style scoped>
button {
    width: 100%;
}

.error-message {
    color: red;
}
</style>