<template>
    <ContentBase> 
        404
    </ContentBase>
</template>

<script>
import ContentBase from '../components/ContentBase.vue';

export default {
    name: 'NotFoundView',
    components: {
        ContentBase,
    }
}
</script>


<style scoped>

</style>