<template>
  <div style="height:60px;"></div> 
  <footer class="navbar-static-bottom py-0 my-0 footer">
    <ul class="nav justify-content-center pb-0 mb-0">
      <li class="nav-item"><a href="https://beian.miit.gov.cn" class="nav-link px-1">陇ICP备2022002384号-1</a></li>
      <li class="nav-item"><a href="#" class="nav-link px-1">公安网备待审核</a></li>
    </ul>
  </footer>
</template>

<script>
export default{
    name: "FooterBar",
}
</script>


<style scoped>
a {
    color: rgba(255, 255, 255, 1);
}
footer{
    position: fixed;
    color:#fff;
    margin-top: 1vh;
    bottom:0;
    width:100%;
    height:5vh;
    text-align:center;
    background-color: #000;
    transform: translate(-50)
}
</style>