<template>
    <ContentBase>
        本站用于展示自己学习开发技术完成的一些项目, 非常简陋, 请见谅.
    </ContentBase>
</template>

<script>
import ContentBase from '../components/ContentBase.vue';

export default {
    components: {
        ContentBase,
    },
    setup() {
        return {
        }
    }
}
</script>


<style scoped>

</style>