<template>
      <div class="container card border-light">
        <div class="card-body">
          <slot></slot>
        </div>
      </div>
</template>
<script>
export default {
    name: "ContentBase",
}
</script>


<style scoped>
.container {
  margin-top: 20px;
}
</style>