<template>
  <ContentBase class="website">
    <ContentBase class="website-head">
      <span class="">欢迎来到我的空间, 你可以找到你喜欢的应用.</span>
    </ContentBase>
    <div class="row">
      <div class="col-7">
        <ContentBase class="website-introduction bg-light">
          <h5>Space 是什么？网站功能</h5>
          <p>Space 是在线的程序应用集合, 主要是我学习开发的一些应用, 欢迎您的访问.</p>
          <br>
          <h5>我不知道怎么用 Space 啊？使用方法</h5>
          <ol>
            <li>注册 Space 账户</li>
            <li>点击登录 </li>
            <li>在导航栏应用中你可以发现我的应用</li>
          </ol>   
        </ContentBase>
      </div>
      <div class="col-1"></div>
      <div class="col-4">
        <ContentBase>
          <div class="alert alert-light" role="alert">
            醉后不知天在水，满船清梦压星河。
          </div>
        </ContentBase>
      </div>
    </div>
  </ContentBase>
</template>

<script>
import ContentBase from '../components/ContentBase.vue';

export default {
  name: 'HomeView',
  components: {
    ContentBase,
  }
}
</script>

<style scoped>
.website {
  width: 100%;
}

.website-head {
  background-color: lightgreen;
}
</style>